import axios from '@/plugins/axios'
import { getAccountId } from '@/helper/auth-helper'

const getMedias = params => axios.get(`${getAccountId()}/medias/paginated`, { params })
const getAllMedias = () => axios.get(`${getAccountId()}/medias/all`)
const createMedia = ({ formData, config }) => axios.post(`${getAccountId()}/medias`, formData, config)
const deleteMedia = mediaId => axios.delete(`${getAccountId()}/medias/${mediaId}`)

export {
  getMedias, getAllMedias, createMedia, deleteMedia
}