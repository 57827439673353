<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="d-flex flex-wrap align-center justify-end">
      <portal to="pageTitle" :disabled="checkMd">
        <div class="font-page-header pr-5 pr-sm-15 mb-md-15">Manage Medias</div>
      </portal>
      
      <RefreshButton class="mr-5 mr-md-11 mb-4 mb-sm-15" :disabled="loading" @click="refresh" />
      
      <v-spacer />
      
      <SearchField
        v-model="search"
        class="mb-4 mb-sm-15"
        @changedSearch="refresh"
      />
      
      <SelectOnPage
        class="pagination-container paginationColor--text ml-5 ml-sm-8 mb-4 mb-sm-15"
        :readonly="loading"
        @changeOnPage="refresh"
      />
    </div>
    
    <div>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :options="options"
        :custom-sort="() => items"
        class="elevation-0 borderNone tableBg rounded-20 borderTable"
        outlined
        disable-pagination
        hide-default-footer
        :loader-height="2"
        :header-props="{ sortIcon: '$sortDownIcon' }"
        :mobile-breakpoint="0"
        @update:options="changeOptions"
      >
        <template #no-data>
          <div class="d-flex">
            <span class="font-normal text-left">No data available</span>
          </div>
        </template>
        
        <template #item.name="{ item }">
          <span class="font-normal text-no-wrap">{{ item.name }}</span>
        </template>
        
        <template #item.recording="{ item }">
          <TooltipAction bottom message="Play">
            <template #activator>
              <v-icon @click="openPlayer(item)">$playIcon</v-icon>
            </template>
          </TooltipAction>
        </template>
        
        <template #item.actions="{ item }">
          <TooltipAction bottom message="Delete">
            <template #activator>
              <v-icon @click="openDeleteMedia(item)">$deleteIcon</v-icon>
            </template>
          </TooltipAction>
        </template>
      </v-data-table>

      <TablePagination
        :loading="loading"
        :dataPagination="dataPagination"
        @input="changePage"
      />

      <div :class="dataPagination.lastPage > 1 ? 'pb-4' : 'pb-26'"></div>
    </div>
    
    <DialogConfirm
      v-model="confirmDialog"
      :loading="loadingAction"
      :title="dialogTitle"
      :subTitle="dialogSubTitle"
      :message="dialogMessage"
      @actionSuccess="deleteMedia"
    />
  
    <v-slide-x-reverse-transition>
      <AudioPlayer
        v-model="playerIsOpen"
        v-show="playerIsOpen"
        :file="audioUrl"
        autoPlay
        @closePlayer="closePlayer"
      />
    </v-slide-x-reverse-transition>
  </v-container>
</template>

<script>
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import RefreshButton from '@/components/buttons/RefreshButton'
import SearchField from '@/components/inputs/SearchField'
import SelectOnPage from '@/components/select/SelectOnPage'
import SwitchSm from '@/components/controls/SwitchSm'
import TooltipAction from '@/components/tooltip/TooltipAction'
import DialogConfirm from '@/components/dialog/DialogConfirm'
import AudioPlayer from '@/components/reporting/AudioPlayer'
import TablePagination from '@/components/pagination/TablePagination'

import { getMedias, deleteMedia } from '@/api/media-routes'
import { getOnPage, parsePaginationData } from '@/helper/app-helper'

export default {
  name: 'ManageMedias',
  components: { ActionOutlinedButton, RefreshButton, SearchField, SelectOnPage, SwitchSm, TooltipAction, DialogConfirm, AudioPlayer, TablePagination },
  data: () => ({
    loading: false,
    loadingAction: false,
    items: [],
    dataPagination: {
      page: 1,
      lastPage: 1,
    },
    options: {},
    headers: [
      { text: 'Name', value: 'name', sortId: 'byName' },
      { text: 'Format', value: 'format', sortId: 'byFormat' },
      { text: 'Recording', value: 'recording', align: 'center', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false, width: 100, align: 'right' },
    ],
    confirmDialog: false,
    selectedItem: null,
    dialogTitle: null,
    dialogSubTitle: null,
    dialogMessage: null,
    serverSorting: null,
    search: null,
    playerIsOpen: false,
    audioUrl: null,
  }),
  computed: {
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
  },
  methods: {
    changeOptions(options) {
      if (!this.loading) {
        this.options = Object.assign({}, options)
      }
    },
    async loadMedias() {
      this.loading = true

      const { success, payload, message } = await getMedias({
        page: this.dataPagination.page,
        onPage: getOnPage(),
        search: this.search,
        ...this.serverSorting,
      })

      if (success) {
        this.dataPagination = parsePaginationData(payload)
        this.items = payload.data
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    changePage(page) {
      if (page !== this.dataPagination.page) {
        this.dataPagination.page = page
        this.loadMedias()
      }
      this.$vuetify.goTo(0)
    },
    openDeleteMedia(media) {
      this.selectedItem = media
      this.dialogTitle = 'Delete media'
      this.dialogSubTitle = `${media.name}`
      this.dialogMessage = 'Are you sure you want to delete this media file?'
      this.confirmDialog = true
    },
    async deleteMedia() {
      this.loadingAction = true

      const { success, message } = await deleteMedia(this.selectedItem.id)

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.confirmDialog = false
        this.refreshDataAfterDelete()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    refreshDataAfterDelete() {
      if (this.items.length === 1 && this.dataPagination.page !== 1) {
        --this.dataPagination.page
      }

      this.loadMedias()
    },
    refresh() {
      this.dataPagination.page = 1
      this.loadMedias()
    },
    openPlayer(file) {
      this.audioUrl = file['full_url']
      this.playerIsOpen = true
    },
    closePlayer() {
      this.audioUrl = null
      this.playerIsOpen = false
    },
  },
  watch: {
    options: {
      deep: true,
      handler(sortOptions) {
        if (sortOptions.sortBy.length) {
          const sortBy = this.headers.find(item => item.value === sortOptions.sortBy[0])
          this.serverSorting = { [sortBy.sortId]: sortOptions.sortDesc[0] ? 'asc' : 'desc' }
        } else {
          this.serverSorting = null
        }
        this.loadMedias()
      },
    },
  },
}
</script>
